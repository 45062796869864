import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Checkbox, TextareaAutosize } from "@mui/material";
import { useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import DPNotificationsEvents from "../Context/DPNotifications";

const FeedbackFormDialog = () => {
  const [open, setOpen] = React.useState(false);
  const [anonymous, setAnonymous] = React.useState(false);
  const [msgLength, setMsgLength] = React.useState(0);

  const { state: routeState } = useLocation();

  const metadata = {
    location: routeState?.routeTitle?.name || "Overview",
    date: new Date().toISOString(),
  };

  const label = { inputProps: { "aria-label": "submit anonymously" } };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setAnonymous(false);
    setMsgLength(0);
    setOpen(false);
  };

  const handleMessageLength = (e) => {
    setMsgLength(e.target.value.length);
  };

  const handleAnonymous = (e) => {
    setAnonymous(e.target.checked);
  };

  const handleSubmit = async (formdata) => {
    let newFormData = { ...formdata };
    const params = {
      headers: {},
      response: true,
      queryStringParameters: {
        type: "feedback",
        formdata: JSON.stringify(newFormData),
      },
      formdata: newFormData,
    };
    const updateResponse = await API.get(
      "icannDnsMainApi",
      "/updateDashboardJson",
      params
    )
      .then((response) => {
        DPNotificationsEvents.dispatch("Form Submitted", "success");
        handleClose();
      })
      .catch((error) => {
        DPNotificationsEvents.dispatch("Error saving form", "error");
        // fetchDashboardSettings();
      });
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        style={{
          backgroundColor: "#fff",
          borderColor: "#047bc1",
          color: "#047bc1",
        }}
      >
        Share Feedback
      </Button>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: "form",
            onSubmit: async (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());

              // Final Payload - Add (send to lambda)
              const data = { ...formJson, metadata: metadata };
              await handleSubmit(data);
            },
          }}
        >
          <DialogTitle style={{ display: "flex", flexDirection: "column" }}>
            <span>DNS Demographics</span>
            <span style={{ fontSize: "0.9rem", color: "#999999" }}>
              Help us improve; your feedback is greatly appreciated!
            </span>
          </DialogTitle>

          {/* <hr style={{ width: "100%" }} /> */}
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <TextField
              required
              margin="dense"
              id="subject"
              name="subject"
              label="Subject"
              type="text"
              fullWidth
              variant="outlined"
              style={{ minWidth: "400px" }}
            />

            <span>
              <TextareaAutosize
                autoFocus
                required
                aria-label="feedback text area"
                id="message"
                name="message"
                label="Your message"
                placeholder="Share your thoughts and suggestions, or report issues here."
                minRows={5}
                style={{ width: "100%", padding: "1rem", resize: "none" }}
                maxLength={250}
                onChange={handleMessageLength}
              />
              <span
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  color: "#999999",
                }}
              >
                {msgLength} / 250
              </span>
            </span>

            <span>
              {!anonymous && (
                <>
                  <DialogContentText>
                    Can we contact you regarding your experience?
                  </DialogContentText>
                  <TextField
                    required={!anonymous}
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                  />
                  <TextField
                    required={!anonymous}
                    margin="dense"
                    id="name"
                    name="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="outlined"
                  />
                </>
              )}

              <span
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <label style={{ color: "rgba(0,0,0,0.6)" }}>
                  Submit anonymously
                </label>
                <Checkbox {...label} onChange={handleAnonymous} />
              </span>
            </span>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} style={{ color: "#047bc1" }}>
              Cancel
            </Button>
            <Button type="submit" style={{ color: "#047bc1" }}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export { FeedbackFormDialog };
