import React, {useEffect, useState} from 'react';
import DPNotificationsEvents from '../Context/DPNotifications';
import { Container, Grid, Box, Button, IconButton, Typography, Paper } from '@mui/material';
import { Accordion, AccordionActions, AccordionSummary, AccordionDetails } from '@mui/material';
import { List, ListItem, ListItemText } from '@mui/material';

import TextField from '@mui/material/TextField';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Tooltip from '@mui/material/Tooltip';
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';

import { API } from 'aws-amplify';
import {useDashboardSettings} from '../Context/Auth'

const SettingsPage = (props) => {

    const {dashboardSettings, setDashboardSettings, fetchDashboardSettings} = useDashboardSettings();

    const [tempDashboardSettings, setTempDashboardSettings] = useState(dashboardSettings);
    useEffect(() => {
        
        setTempDashboardSettings(dashboardSettings);
    }, [dashboardSettings])
    
    
    const changeTextValue = (event, key, index) => {
        let tempMenuList = [...tempDashboardSettings];
        tempMenuList[index][key] = event.target.value;
        setDashboardSettings([...tempMenuList]);
    }
    
    const changeOverviewTextValue = (event, key, index) => {
        let tempMenuList = [...tempDashboardSettings];
        tempMenuList[index]['overview'][key] = event.target.value;
        setDashboardSettings([...tempMenuList]);
    }
    
    const changeOverviewItemTextValue = (event, index, overviewItemIndex) => {
        let tempMenuList = [...tempDashboardSettings];
        tempMenuList[index]['overview']['items'][overviewItemIndex] = event.target.value;
        setDashboardSettings([...tempMenuList]);
    }
    
    const removeItem = (event, index) => {
        let tempMenuList = [...tempDashboardSettings];
        tempMenuList.splice(index, 1);
        setDashboardSettings([...tempMenuList]);
    }
    
    const addMenuItem = () => {
        let tempItem = {
            name:'',
            url:'',
            id:'',
            guid:uuidv4(),
            overview:{
                        pretext:'',
                        posttext:'',
                        items:[]
                    }
        }
        let tempMenuList = [...tempDashboardSettings];
        tempMenuList.push(tempItem);
        setDashboardSettings([...tempMenuList]);
    }
    
    const addOverviewItem = (menuItemId) => {
        let menuItem = tempDashboardSettings.filter(item => item.guid === menuItemId)[0];
        if(menuItem['overview'] && menuItem['overview']['items'])
        {
            menuItem['overview']['items'].push('New bullet point');
        }
        else
        {
            menuItem['overview'] = {
                pretext:'',
                posttext:'',
                items:[]
            }
            menuItem['overview']['items'].push('New bullet point');
        }
        setDashboardSettings([...tempDashboardSettings]);
    }
    
    const removeOverviewItem = (menuItemId, overviewItemId) => {
        let menuItem = tempDashboardSettings.filter(item => item.guid === menuItemId)[0];
        if(menuItem['overview'] && menuItem['overview']['items'])
        {
            menuItem['overview']['items'].splice(overviewItemId, 1);
        }
        setDashboardSettings([...tempDashboardSettings]);
    }
    
    const uuidv4 = () => {
      function random() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return random() + random() + '-' + random() + '-' + random() + '-' +
        random() + '-' + random() + random() + random();
    }
    
    
    const saveDashboards = async () => {
        DPNotificationsEvents.dispatch("Saving Settings");
        let newDashboards = [...tempDashboardSettings];
        const params = { 
            headers: {},
            response: true,
            queryStringParameters: {
                dashboards:JSON.stringify(newDashboards),
            },
            dashboards:newDashboards,
        }
        console.log(newDashboards);
        console.log(params);
        const updateResponse = await API.get('icannDnsMainApi', '/updateDashboardJson', params)
        .then((response) => {
            DPNotificationsEvents.dispatch("Settings Saved", "success");
            fetchDashboardSettings();
        })
        .catch((error) => {
            DPNotificationsEvents.dispatch("Error saving settings", "error");
            fetchDashboardSettings();
        });
        console.log(updateResponse);
    }
    
    const moveItemInArray = (e, index, moveUp=true, moveDown=false) => {
        let tempMenuList = [...tempDashboardSettings];
        let currentItem = tempMenuList[index];
        let targetIndex = tempMenuList.length - 1;
        tempMenuList.splice(index, 1);
        
        if(moveUp)
        {
            if(index == 0){return;}
            targetIndex = index - 1;
        }

        if(moveDown)
        {
            if(index == tempMenuList.length - 1){return;}
            targetIndex = index + 1;
        }

        tempMenuList.splice(targetIndex, 0, currentItem);
        setDashboardSettings([...tempMenuList]);
    }
    
    const moveOverviewItemInArray = (e, index, overviewItemIndex, moveUp=true, moveDown=false) => {
        let tempMenuList = [...tempDashboardSettings[index]['overview']['items']];
        let currentItem = tempMenuList[overviewItemIndex];
        let targetIndex = tempMenuList.length - 1;
        tempMenuList.splice(overviewItemIndex, 1);
        
        if(moveUp)
        {
            if(overviewItemIndex == 0){return;}
            targetIndex = overviewItemIndex - 1;
        }

        if(moveDown)
        {
            if(overviewItemIndex == tempMenuList.length - 1){return;}
            targetIndex = overviewItemIndex + 1;
        }

        tempMenuList.splice(targetIndex, 0, currentItem);
        tempDashboardSettings[index]['overview']['items'] = tempMenuList;
        setDashboardSettings([...tempDashboardSettings]);
    }


    return(
        <Container>
            <Box marginTop={2} sx={{width:'90%', margin:'2 auto'}}>
                <Grid
                    container
                    direction={'column'}
                    spacing={2}
                >
                    <Grid
                        container
                        justifyContent={'flex-end'}
                        marginY={2}
                    >
                        <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                startIcon={<AddCircleTwoToneIcon />}
                                onClick={(e) => addMenuItem()}
                            >
                                Add
                            </Button>
                        </Grid>
                        
                    </Grid>
                {
                    tempDashboardSettings.map((item, itemIndex) => {
                        if(item.guid === '' || !item.guid)
                        {
                            item.guid = uuidv4();
                        }

                        return(
                            <Accordion
                                key={item.guid + '_' + itemIndex}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Grid container justifyContent={"space-between"}>
                                        <Grid item>
                                            {item.name}
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid
                                        container
                                        justifyContent={'space-between'}
                                        marginY={1}
                                    >
                                        <Grid item>
                                            <Tooltip title="Enter the dashboard name">
                                                <TextField 
                                                    id={`name_txt_${item.guid}`}
                                                    label='Name'
                                                    value={item.name}
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={(e) => changeTextValue(e, 'name', itemIndex)}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title="Enter your Quicksite Dashboard URL">
                                                <TextField 
                                                    id={`url_txt_${item.guid}`}
                                                    label='URL'
                                                    value={item.url}
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={(e) => changeTextValue(e, 'url', itemIndex)}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title="Enter your Quicksite Dashboard ID">
                                                <TextField 
                                                    id={`id_txt_${item.guid}`}
                                                    label='Dashboard ID'
                                                    value={item.id}
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={(e) => changeTextValue(e, 'id', itemIndex)}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent={'space-between'}
                                        marginY={1}
                                    >
                                        <Grid item flex={1}>
                                            <Tooltip title="Description shown at the top of the page">
                                                <TextField 
                                                    id={`description_txt_${item.guid}`}
                                                    label='Page Description'
                                                    value={item.description}
                                                    variant="outlined"
                                                    size="small"
                                                    multiline
                                                    rows={4}
                                                    sx={{width:"100%"}}
                                                    onChange={(e) => changeTextValue(e, 'description', itemIndex)}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    <Paper elevation={1}>
                                        <Grid
                                            container
                                            justifyContent={'space-between'}
                                            marginY={1}
                                            padding={1}
                                            direction={"column"}
                                        >
                                            <Grid item marginBottom={1}>
                                                <Typography variant="h6" sx={{color:"#047bc1"}}>
                                                    Overview Settings
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Overview page Pre Text">
                                                    <TextField 
                                                        id={`pretext_txt_${item.guid}`}
                                                        label='Overview PreText'
                                                        value={item.overview?.pretext}
                                                        variant="outlined"
                                                        size="small"
                                                        multiline
                                                        rows={2}
                                                        sx={{width:"100%"}}
                                                        onChange={(e) => changeOverviewTextValue(e, 'pretext', itemIndex)}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    direction="column"
                                                >
                                                    <Grid item alignSelf="flex-end">
                                                        <Tooltip title="Add bullet point">
                                                            <IconButton 
                                                                edge="end" 
                                                                aria-label="Add bullet point"
                                                                color="info"
                                                                onClick={(e) => addOverviewItem(item.guid)}
                                                            >
                                                                <AddCircleTwoToneIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <List>
                                                            {
                                                                item.overview?.items.map((overviewItem, overviewItemIndex) => {
                                                                    return(
                                                                        <ListItem
                                                                            key={`overviewBullet_${item.guid}_${overviewItemIndex}`}
                                                                            secondaryAction={
                                                                                            <div>
                                                                                                <Tooltip title="Move up">
                                                                                                    <span>
                                                                                                    <IconButton 
                                                                                                        edge="end" 
                                                                                                        aria-label="movePointUp"
                                                                                                        color="primary"
                                                                                                        disabled={overviewItemIndex === 0}
                                                                                                        onClick={(e) => moveOverviewItemInArray(e, itemIndex, overviewItemIndex, true, false)}
                                                                                                    >
                                                                                                        <ArrowCircleUpTwoToneIcon />
                                                                                                    </IconButton>
                                                                                                    </span>
                                                                                                </Tooltip>
                                                                                                <Tooltip title="Move down">
                                                                                                    <span>
                                                                                                    <IconButton 
                                                                                                        edge="end" 
                                                                                                        aria-label="movePointDown"
                                                                                                        color="primary"
                                                                                                        disabled={overviewItemIndex === item.overview?.items.length - 1}
                                                                                                        onClick={(e) => moveOverviewItemInArray(e, itemIndex, overviewItemIndex, false, true)}
                                                                                                    >
                                                                                                        <ArrowCircleDownTwoToneIcon />
                                                                                                    </IconButton>
                                                                                                    </span>
                                                                                                </Tooltip>
                                                                                                <Tooltip title="Delete">
                                                                                                    <IconButton 
                                                                                                        edge="end" 
                                                                                                        aria-label="Delete bullet point"
                                                                                                        color="error"
                                                                                                        onClick={(e) => removeOverviewItem(item.guid, overviewItemIndex)}
                                                                                                    >
                                                                                                        <DeleteIcon />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                            }
                                                                        >
                                                                            <ListItemText 
                                                                                id={`overviewBullet_${item.guid}_${overviewItemIndex}_listText`}
                                                                                primary={
                                                                                    <Tooltip title="Bullet point text">
                                                                                        <TextField 
                                                                                            id={`overviewBullet_txt_${item.guid}_${overviewItemIndex}`}
                                                                                            label='Bullet Text'
                                                                                            value={overviewItem}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                            multiline
                                                                                            rows={2}
                                                                                            sx={{width:"80%"}}
                                                                                            onChange={(e) => changeOverviewItemTextValue(e, itemIndex, overviewItemIndex)}
                                                                                        />
                                                                                    </Tooltip>
                                                                                }
                                                                            />
                                                                        </ListItem>
                                                                    )
                                                                })
                                                            }
                                                        </List>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Overview page Post Text">
                                                    <TextField 
                                                        id={`pretext_txt_${item.guid}`}
                                                        label='Overview PostText'
                                                        value={item.overview?.posttext}
                                                        variant="outlined"
                                                        size="small"
                                                        multiline
                                                        rows={2}
                                                        sx={{width:"100%"}}
                                                        onChange={(e) => changeOverviewTextValue(e, 'posttext', itemIndex)}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </AccordionDetails>
                                <AccordionActions>
                                    {
                                        itemIndex != 0 &&
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                startIcon={<ArrowCircleUpTwoToneIcon />}
                                                onClick={(e) => moveItemInArray(e, itemIndex, true, false)}
                                            >
                                                Move Up
                                            </Button>
                                    }
                                    {
                                        itemIndex != (tempDashboardSettings.length - 1) &&
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                startIcon={<ArrowCircleDownTwoToneIcon />}
                                                onClick={(e) => moveItemInArray(e, itemIndex, false, true)}
                                            >
                                                Move Down
                                            </Button>
                                    }
                                    
                                    
                                    <Button
                                        variant='contained'
                                        color='error'
                                        startIcon={<DeleteTwoToneIcon />}
                                        onClick={(e) => removeItem(e, itemIndex)}
                                    >
                                        Delete
                                    </Button>
                                </AccordionActions>
                            </Accordion>
                        )
                    })
                }
                    <Grid
                        container
                        justifyContent={'flex-end'}
                        marginY={2}
                    >
                        <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                startIcon={<SaveTwoToneIcon />}
                                onClick={(e) => saveDashboards()}
                            >
                                Save
                            </Button>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default SettingsPage;
