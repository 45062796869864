import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Embed from '../Comps/Embed';
import {useParams} from 'react-router-dom';
import jsPDF from 'jspdf';

import {Container, Box, Grid, Button} from '@mui/material/';

import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';

const Dashboard = () => {
    
    const params = useParams();
    const [dashboardId, setDashboardId] = useState('');

    let dashboardName = 'Dashboard';
    const {state:routeState} = useLocation();
    if(routeState)
    {
        routeState.routeTitle?.name && (dashboardName = routeState.routeTitle.name || '')
    }

    useEffect(() => {
        setDashboardId(params.guid);
    }, [params])
    
    
    const exportToPdf = () => {
        let element = document.getElementById("dashboardContainer");
        var doc = new jsPDF('p', 'pt', 'a4');
        let fileName = dashboardName + new Date().toISOString();

        doc.html(element, {
            callback: (doc) => 
                {
                    doc.save(fileName + '.pdf');
                },
            x:10,
            y:10,
            html2canvas: { scale: 0.555 },
         });
    }
    

    return(
        <Box>
            <Box
                sx={{
                    display:"flex",
                    textAlign:"justify",
                    marginBottom:"25px",
                    fontSize:"0.8rem"
                }}
            >
                        Note: The value displayed in each pie chart represents the unique count of items that meet the selection criteria for that particular chart and dashboard. For example, the value in the centre of the pie chart titled “ICANN Geographic regions” in the “ccTLD Overview” dashboard shows the total number of ccTLDs across all of ICANN’s geographic regions.
            </Box>
            {/*<Grid
                container
                justifyContent={'flex-end'}
                marginY={2}
            >
                <Grid item>
                    <Button
                        variant='contained'
                        color='primary'
                        startIcon={<FileDownloadTwoToneIcon />}
                        onClick={(e) => exportToPdf()}
                    >
                        Export to PDF
                    </Button>
                </Grid>
                
            </Grid>*/}
            <Container disableGutters>
                <Embed 
                    key={dashboardId}
                    DashboardId={dashboardId}
                    id="pdfPrintSource"
                />
            </Container>
        </Box>
    )
}

export default Dashboard;