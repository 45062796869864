import React from 'react';
import { API, Auth } from 'aws-amplify';

var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

class Embed extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loader: true
        };
    }
    
    componentDidMount() {
        this.getQuickSightDashboardEmbedURL();
        this.getQuickSightDashboardList();
    }
    
    getQuickSightDashboardEmbedURL = async () => {
        const data = await Auth.currentSession();
        const jwtToken = data.idToken.jwtToken;
        const payloadSub = data.idToken.payload.sub;
        const email = data.idToken.payload.email;
        
        const params = { 
            headers: {},
            response: true,
            queryStringParameters: {
                jwtToken: jwtToken,
                payloadSub: payloadSub,
                email: email,
                dashboardId:this.props.DashboardId,
            },
        }
        const quicksight = await API.get('quicksight', '/getQuickSightDashboardEmbedURL', params);
        console.log(quicksight);
        console.log('run');
        const containerDiv = document.getElementById("dashboardContainer");
        
        if(quicksight?.data?.data?.EmbedUrl)
        {
            const options = {
                url: quicksight?.data?.data?.EmbedUrl,
                container: containerDiv,
                parameters: {
                    // REGIONALCCLTDORG:"AFTLD"
                },
                scrolling: "no",
                height: "800px",
                width: "100%",
                footerPaddingEnabled: true,
                printEnabled: true,
            };
            const dashboard = QuickSightEmbedding.embedDashboard(options);
            this.setState({ loader: false });
        }
        
        
    };
    
    
    getQuickSightDashboardList = async () => {
        const data = await Auth.currentSession();
        const jwtToken = data.idToken.jwtToken;
        const payloadSub = data.idToken.payload.sub;
        const email = data.idToken.payload.email;
        
        const params = { 
            headers: {},
            response: true,
            queryStringParameters: {
                jwtToken: jwtToken,
                payloadSub: payloadSub,
                email: email,
                dashboardId:this.props.DashboardId,
            },
        }
        // const quicksight = await API.get('quicksight', '/getQuickSightDashboardList', params);
        // console.log(quicksight);
    };
    
    render() {
        // const {  } = this.props;
        return (
            <div>
                { this.state.loader && (
                    <div> Loading ... </div>
                )}
                <div id="dashboardContainer"></div>
            </div>
        );
    }
}

export default Embed;