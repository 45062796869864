import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useAuth} from '../Context/Auth'

//import ui elements
import AppBarBackground from '../Media/ICANN_Dash_BG_TopRight.png';
import {AppBar, Toolbar, Container, Grid, Hidden, Typography, IconButton, Tooltip, Avatar, Menu, Divider, MenuItem} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
//import Brightness2Icon from '@mui/icons-material/Brightness2';
// import Brightness7Icon from '@mui/icons-material/Brightness7';

const AppHeader = (props) => {
    //aws-amplify objects
    const {user, signOut} = useAuth();

    let currentRoute = useLocation().pathname;
    let currentDescription = '';
    if(currentRoute === '/')
    {
        currentRoute = 'Overview';
    }
    currentRoute = currentRoute.replace(/\//g, '');

    const {state:routeState} = useLocation();
    if(routeState)
    {
        routeState.routeTitle?.name && (currentRoute = routeState.routeTitle.name || '')
        routeState.routeTitle?.description && (currentDescription = routeState.routeTitle.description || '')
    }

    //prop drilled values
    const {toggleMobileOpen} = {...props};

    //State
    const [userProfileMenuAnchor, setUserProfileMenuAnchor] = useState(null);


    //Helper functions
    const handleMenu = (event) => {
        setUserProfileMenuAnchor(event.currentTarget);
    }
    const handleClose = () => {
        setUserProfileMenuAnchor(null);
    }


    return(
        <AppBar 
            color="primary" 
            position="sticky" 
            elevation={0}
            enableColorOnDark
            sx={{height:"100px", justifyContent:"center", backgroundImage:`url(${AppBarBackground})` ,backgroundRepeat:"no-repeat",backgroundSize:"60% 100%", backgroundPosition:"center right"}}
        > 
            <Toolbar variant="dense">
                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                    <Hidden smUp>
                        <Grid item>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={ () => {toggleMobileOpen(true)} }
                            >
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                    </Hidden>
                    <Grid item flex={1}>
                        <Typography variant="h5">{currentRoute}</Typography>
                        <Typography variant="body2">{currentDescription}</Typography>
                    </Grid>
                    {/*<Grid item xs />*/}
                    {/* <Hidden>
                        <Grid item>
                            <Tooltip title="Change Theme">
                                <IconButton color="inherit" onClick={ () => {toggleThemeMode()} }>
                                    { currentTheme.palette.mode==='dark'? <Brightness2Icon/> : <Brightness7Icon/>}
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Hidden> */}
                    <Grid item>
                        <Tooltip title={ user?.attributes?.email || ''}>
                            <IconButton id="userProfileAvatar_Header" color="inherit" onClick={ handleMenu}>
                            <Avatar src="/static/images/avatar/1.jpg" alt={user?.attributes?.email || ''} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="userProfileMenu_Header"
                            anchorEl={userProfileMenuAnchor}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            open={Boolean(userProfileMenuAnchor)}
                            onClose={ handleClose }
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                    overflow: 'visible',
                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                    mt: 1.5,
                                                    '& .MuiAvatar-root': {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1,
                                                },
                                                '&:before': {
                                                                content: '""',
                                                                display: 'block',
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 14,
                                                                width: 10,
                                                                height: 10,
                                                                bgcolor: 'background.paper',
                                                                transform: 'translateY(-50%) rotate(45deg)',
                                                                zIndex: 0,
                                                },
                                            },
                                        }}
                            
                        >
                            <Container sx={{display:`flex`, justifyContent:`center`}}>
                                <Avatar alt={ user?.attributes?.email || '' } src={`avatarImageSrc`} sx={{ width: `60px!important`, height: `60px!important` }} />
                            </Container>
                            <Container sx={{display:`flex`, justifyContent:`center`}}>
                                <Typography variant='h6'> {user?.attributes?.email || ''} </Typography>
                            </Container>
                            <Container sx={{display:`flex`, justifyContent:`center`}}>
                                <Typography> {user?.attributes?.email || ''} </Typography>
                            </Container>
                            <Divider />

                            {/* <MenuItem onClick={handleClose }>Settings</MenuItem> */}

                            <MenuItem onClick={ event => signOut() } >Log Out</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Toolbar>
            {/*<Hidden smDown>
                <Toolbar variant="dense" >
                    <Grid container spacing={1} alignItems="center">
                        <Typography variant="h5">{currentRoute}</Typography>
                    </Grid>
                </Toolbar>
            </Hidden>*/}
        </AppBar>
    )
}

export {AppHeader};