import React, {useState, useEffect} from 'react';
import './App.css';

import {Auth} from 'aws-amplify';
import { useAuth } from './Context/Auth';
import '@aws-amplify/ui-react/styles.css'


//Components
import {AppNavigation} from './Comps/AppNavigator';
import {AppHeader} from './Comps/AppHeader';
import AppMessageQueue from './Comps/AppMessageQueue';

//Navigation and Routes Declarations
import { BrowserRouter as Router,  Routes, Route, Navigate} from "react-router-dom";
import * as ROUTES from './Const/routes';

//Import Pages
import Overview from './Pages/Overview';
import Dashboard from './Pages/Dashboard';
import SettingsPage from './Pages/Settings';
import FeedbackOverviewPage from './Pages/FeedbackOverview';

import {useAppSettings} from './Context/AppSettings';

import {CssBaseline, Box, Hidden} from '@mui/material';
import {ThemeProvider, createTheme} from '@mui/material/styles';

const DrawerWidth = 240;


const App = () => {
  const {isAuth} = useAuth();
  const [mobileOpen, setMobileOpen] = useState(false);
  const {currentTheme} = useAppSettings();

  const theme = createTheme(currentTheme);

  //helper functions
  const toggleMobileMenu = (newValue = !mobileOpen)=> {
      setMobileOpen(newValue);
  }

  return (
    <ThemeProvider theme={theme}>
          <Box sx={{ display: 'flex', flex:1}}>
            <CssBaseline />

                {isAuth &&
                
                  <Router>
                    <Box
                      component="nav"
                      sx={{ width: { sm: DrawerWidth }, display:`flex`, flexShrink: { sm: 0 } }}
                    >
                      <Hidden smUp>
                          <AppNavigation drawerWidth={DrawerWidth} variant="temporary" toggleMobileOpen={toggleMobileMenu} mobileMenuOpen={mobileOpen}  />
                      </Hidden>
                      <Hidden smDown>
                          <AppNavigation drawerWidth={DrawerWidth} variant="permanent" toggleMobileOpen={toggleMobileMenu} mobileMenuOpen={mobileOpen} />
                      </Hidden>
                    </Box>

                    <Box
                      component="main"
                      sx={{ display: 'flex',  width: `100%`, flexDirection:`column`, flex:1}}
                    >

                      <Box>
                        <AppHeader toggleMobileOpen={toggleMobileMenu} mobileMenuOpen={mobileOpen} />
                      </Box>

                      <Box sx={{padding:'10px', paddingBottom:0}}>
                        <Routes>
                          <Route path={ROUTES.HOME} element={<Overview /> } />
                          <Route path={ROUTES.OVERVIEW} element={<Overview /> } />
                          <Route path={ROUTES.DASHBOARD} element={<Dashboard /> } />
                          <Route path={ROUTES.SETTINGS} element={<SettingsPage /> } />
                          <Route path={ROUTES.FEEDBACK} element={<FeedbackOverviewPage /> } />
                        </Routes>
                      </Box>

                    </Box>
                  </Router>
                }
          </Box>

          <AppMessageQueue 
            duration={3000}
          />

    </ThemeProvider>

  )
}

export default App;