import React, {useEffect, useState} from 'react';
import DPNotificationsEvents from '../Context/DPNotifications';
import { Container, Grid, Box, Button, IconButton, Typography, Paper } from '@mui/material';
import { Accordion, AccordionActions, AccordionSummary, AccordionDetails } from '@mui/material';
import { List, ListItem, ListItemText } from '@mui/material';

import TextField from '@mui/material/TextField';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Tooltip from '@mui/material/Tooltip';
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone';

import CloudDownloadTwoToneIcon from '@mui/icons-material/CloudDownloadTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';

import { API } from 'aws-amplify';
import {useDashboardSettings} from '../Context/Auth'

const FeedbackOverviewPage = (props) => {

    const [feedbackItems, setFeedbackItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        loadFeedbackItems();
    }, [])
    
    const loadFeedbackItems = async () => {

        setIsLoading(true);
        const params = {
          headers: {},
          response: true,
          queryStringParameters: {
            type: "read-feedback",
            start:'',
            limit:100,
          },
        };
        const updateResponse = await API.get(
          "icannDnsMainApi",
          "/feedbackAPI",
          params
        )
          .then((response) => {
            if(response?.data?.files)
            {
                setFeedbackItems(response.data.files);
                setIsLoading(false);
            }
          })
          .catch((error) => {
            DPNotificationsEvents.dispatch("Error reading feedback files", "error");
          });   
    }
    
    
    const removeItem = async  (fileItem, fromDeleteAll = false) => {
        const params = {
          headers: {},
          response: true,
          queryStringParameters: {
            type: "delete-feedback",
            uuid:`${fileItem.FileName}`,
          },
        };
        const updateResponse = await API.get(
          "icannDnsMainApi",
          "/feedbackAPI",
          params
        )
          .then((response) => {
            if(!fromDeleteAll)
            {
                DPNotificationsEvents.dispatch("Feedback deleted", "warning");
            }
            loadFeedbackItems();
          })
          .catch((error) => {
            DPNotificationsEvents.dispatch("Error deleting feedback", "error");
            loadFeedbackItems();
          }); 
    }
    
    const downloadFile = ({ data, fileName, fileType }) => {
        // Create a blob with the data we want to download as a file
        const blob = new Blob([data], { type: fileType })
        // Create an anchor element and dispatch a click event on it
        // to trigger a download
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const convertToCSV = (data) => {
        const header = Object.keys(data[0]);
        const headerString = header.join(',');
        // handle null or undefined values here
        const replacer = (key, value) => value ?? '';
        const rowItems = data.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(',')
        );
        // join header and body, and break into separate lines
        const csv = [headerString, ...rowItems].join('\r\n');
        return csv;
    }

    const downloadFileByIndex = (index, type) => {
        let downObject;
        let dataString;
        downObject = {...feedbackItems[index]};
        
        let fileName = `${downObject.metadata.location}_${downObject.metadata.date}`;
        let fileType = 'text/json';
        
        //normalize downloading data here
        downObject.date = downObject.metadata.date;
        downObject.location = downObject.metadata.location;
        delete downObject.metadata;
        
        if(type == "csv")
        {
            dataString = convertToCSV([downObject])
            fileType = 'text/csv';
            fileName += `.csv`;
        }
        else
        {
            dataString = JSON.stringify(downObject);
            fileType = 'text/json';
            fileName += `.json`;
        }
        
        //download file
        downloadFile({
            data: dataString,
            fileName: fileName,
            fileType: fileType,
        })
    }
    
    const downloadAllFiles = (type) => {

        let downObject;
        let dataString;
        downObject = [];
  
        for(let item of  feedbackItems)
        {
            downObject.push({...item});
        }
        
        let fileName = `${new Date().toISOString()}`;
        let fileType = 'text/json';
        
        //normalize downloading data here
        downObject.forEach(item => {
            item.date = item.metadata.date;
            item.location = item.metadata.location;
            delete item.metadata;
        })
        
        
        if(type == "csv")
        {
            dataString = convertToCSV(downObject)
            fileType = 'text/csv';
            fileName += `.csv`;
        }
        else
        {
            dataString = JSON.stringify(downObject);
            fileType = 'text/json';
            fileName += `.json`;
        }
        
        /* console.log(dataString); */
        //download file
        downloadFile({
            data: dataString,
            fileName: fileName,
            fileType: fileType,
        })
    }
    
    const deleteAllFiles = () => {
        let newFileList = [];
        
        for(let item of  feedbackItems)
        {
            newFileList.push({...item});
        }
        
        for(let item of  newFileList)
        {
            removeItem(item, true);
        }
        
        DPNotificationsEvents.dispatch("All feedback has been deleted", "error");
    }


    return(
        <Container>
            { 
                isLoading &&
                <div> Loading ... </div>
            }
            
            <Box marginTop={2} sx={{width:'90%', margin:'2 auto'}}>
                <Grid
                    container
                    direction={'column'}
                    spacing={2}
                >
                {
                    feedbackItems.map((item, itemIndex) => {

                        return(
                            <Accordion
                                key={item.FileName + '_' + itemIndex}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Grid container justifyContent={"space-between"}>
                                        <Grid item>
                                            {item.subject} (<Typography variant="caption">{item.metadata.date}</Typography>)
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid
                                        container
                                        justifyContent={'space-between'}
                                        marginY={1}
                                        spacing={1}
                                    >
                                        <Grid item flex={1}>
                                            <Grid container justifyContent={'space-between'} marginY={1} direction={'column'} spacing={1}>
                                                <Grid item>
                                                    <strong>Location : </strong>{item.metadata.location}
                                                </Grid>
                                                <Grid item>
                                                    <strong>Name : </strong>{item.name}
                                                </Grid>
                                                <Grid item>
                                                    <strong>Email : </strong>{item.email}
                                                </Grid>
                                                <Grid item>
                                                    <strong>Filename : </strong>{item.FileName.replace('Feedback/', '').replace('.json', '')}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item flex={1}>
                                            <Grid container justifyContent={'space-between'} marginY={1} direction={'column'}  spacing={1}>
                                                <Grid item>
                                                    <strong>Message : </strong>
                                                </Grid>
                                                <Grid item sx={{whiteSpace:'pre-line'}}>
                                                    {item.message}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                        
                                </AccordionDetails>
                                <AccordionActions>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        startIcon={<CloudDownloadTwoToneIcon />}
                                        onClick={(e) => downloadFileByIndex(itemIndex, "json")}
                                    >
                                        JSON
                                    </Button>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        startIcon={<CloudDownloadTwoToneIcon />}
                                        onClick={(e) => downloadFileByIndex(itemIndex, "csv")}
                                    >
                                        CSV
                                    </Button>
                                    <Button
                                        variant='contained'
                                        color='error'
                                        startIcon={<DeleteTwoToneIcon />}
                                        onClick={(e) => removeItem(item)}
                                    >
                                        Delete
                                    </Button>
                                </AccordionActions>
                            </Accordion>
                        )
                    })
                }
                    <Grid
                        container
                        justifyContent={'flex-end'}
                        marginY={2}
                        spacing={2}
                    >
                        <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                startIcon={<CloudDownloadTwoToneIcon />}
                                onClick={(e) => downloadAllFiles("json")}
                                disabled={!feedbackItems || (!feedbackItems.length > 0)}
                            >
                                All to JSON
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                startIcon={<CloudDownloadTwoToneIcon />}
                                onClick={(e) => downloadAllFiles("csv")}
                                disabled={!feedbackItems || (!feedbackItems.length > 0)}
                            >
                                All to CSV
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                color='error'
                                startIcon={<DeleteTwoToneIcon />}
                                onClick={(e) => deleteAllFiles()}
                                disabled={!feedbackItems || (!feedbackItems.length > 0)}
                            >
                                Delete All
                            </Button>
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default FeedbackOverviewPage;
