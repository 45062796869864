import React from 'react';
import ICANN_Dash_LogoTopLeft from '../Media/ICANN_Dash_LogoTopLeft.png'
import ICANN_Login_LogoBar from '../Media/ICANN_Login_LogoBar.png'

import {Stack} from '@mui/material'


const AppLogo = (props) => {


return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{height:100, backgroundColor:'#047bc1'}} >
        <img src={ICANN_Dash_LogoTopLeft} width="100%" alt="logo" />
    </Stack>
)


}

const LoginLogo = (props) => {


return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{height:70, backgroundColor:'#047bc1'}} >
        <img src={ICANN_Login_LogoBar} width="100%" alt="logo" />
    </Stack>
)


}





export {AppLogo, LoginLogo};