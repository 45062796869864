import React, { createContext, useContext, useState, useEffect} from "react";
//import new auth comps
import Amplify, {Auth, Hub} from 'aws-amplify';

const AuthContext = createContext({});
const DashboardSettingsContext = createContext({});

const extractAttributes = (attributes) => {
    let atts = {}
    try {
        attributes.map(att => {
            atts[att.Name] = att.Value;
        })
            
    } catch (e) {
        
    }
    return atts;
}

const DashboardSettingsProvider = (props) => {
    const {children} = {...props};
    
    const [dashboardSettings, setDashboardSettings] = useState([]);
    
    useEffect(() => {
        fetchDashboardSettings();
    }, [])
    
    const fetchDashboardSettings = () => {
        fetch('/dashboards.json')
        .then((res) => res.json())
        .then((data) => setDashboardSettings(data))
        .catch(err => {console.log(err)})
    }
    
    const dashboardSettingsContextValue = {
        dashboardSettings,
        fetchDashboardSettings,
        setDashboardSettings
    }
    
    return(
        <DashboardSettingsContext.Provider value={dashboardSettingsContextValue} {...props}>
            {children}
        </DashboardSettingsContext.Provider>
    )
}


const AuthProvider = (props) => {
    const {children} = {...props};
    
    const [isAuth, setIsAuth] = useState(false);
    const [user, setUser] = useState();
    const [userGroups, setUserGroups] = useState([]);
    
    
    
    Hub.listen('auth', ({payload:{event, data}}) => {
        switch(event){
          case 'signIn':
              Auth.userAttributes(data).then(userAtts => {
                  data.attributes = {...extractAttributes(userAtts)};
                  setUser({...data});
                  setIsAuth(true);
              }).catch(err => {
                  console.log(err);
                  data.attributes = {};
                  setUser({...data});
                  setIsAuth(true);
              })
            break;
          case 'signOut':
            setUser(null);
            setIsAuth(false);
           window.location.replace('https://dns-demographics.auth.us-west-2.amazoncognito.com/login?client_id=2r0aernnrpnbt07mobksl404jj&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https://dns-demographics.icann.org')
            break;
        
        }
      })
      
     useEffect(() => {
         Auth.currentAuthenticatedUser().then(recUser => {
             //console.log(recUser);
          Auth.userAttributes(recUser).then(userAtts => {
              //console.log(userAtts);
              recUser.attributes = {...extractAttributes(userAtts)};
              setUser({...recUser});
              setUserGroups([...recUser.signInUserSession.accessToken.payload["cognito:groups"]])
              setIsAuth(true);
          }).catch(err => {
              console.log(err);
              recUser.attributes = {};
              setUser({...recUser});
              setUserGroups([]);
              setIsAuth(true);
          })
        }).catch(() => {
            setUser(null);
           setIsAuth(false);
           window.location.replace('https://dns-demographics.auth.us-west-2.amazoncognito.com/login?client_id=2r0aernnrpnbt07mobksl404jj&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https://dns-demographics.icann.org')
        })
     }, [])
     
     const signOut = () => {
         Auth.signOut();
     }
     
    
    

    const authContextValue = {
        isAuth,
        user,
        userGroups,
        signOut,
    }

    return(
     
        <AuthContext.Provider value={authContextValue} {...props}>
            <DashboardSettingsProvider>
                {children}
            </DashboardSettingsProvider>
        </AuthContext.Provider>
    )

}


const useAuth = () => useContext(AuthContext);
const useDashboardSettings = () => useContext(DashboardSettingsContext);

export {AuthProvider, useAuth, useDashboardSettings};

