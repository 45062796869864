import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import BreakpointLogo from '../Media/breakpoint_logo.png'

import {useNavigate} from 'react-router-dom';

import {AppLogo} from './AppExtras';

import {Drawer, Divider, List, ListItem, ListItemText, ListItemIcon, Box} from '@mui/material';
import { FeedbackFormDialog } from "./FeedbackForm";

import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import InfoIcon from '@mui/icons-material/Info';
import MessageIcon from '@mui/icons-material/Message';

import {useAuth, useDashboardSettings} from '../Context/Auth'


function AppNavigation(props) {
    
    const {user, userGroups} = useAuth();
    const {dashboardSettings, fetchDashboardSettings} = useDashboardSettings();
    //console.log(user);
    const { window } = props;
    const container = window !== undefined ? () => window().document.body : undefined;
    const navigate = useNavigate();

    //prop drilled values
    const {mobileMenuOpen, toggleMobileOpen} = {...props};
    
    //State management
    let currentRoute = useLocation().pathname;
    if(currentRoute === '/')
    {
        currentRoute = 'Overview';
    }
    currentRoute = currentRoute.replace(/\//g, '');
    
    useEffect(() => {
        fetchDashboardSettings();
    }, [])

    
    const navigateTo = (route, title, data = {}) => {
        fetchDashboardSettings();
        let routeState = {routeTitle:title, routeData:data}
        navigate(route, {state:routeState});
        toggleMobileOpen(false);
    }
    
    const [showSettingsMenu, setShowSettingsMenu] = useState(false);
    useEffect(() => {
        if(user?.attributes['custom:UserGroup_Dashboard'])
        {
            if(user?.attributes['custom:UserGroup_Dashboard'] === 'DNSDemographic_DNSD_admin_member')
            {
                setShowSettingsMenu(true);
            }
        }
        if(userGroups.includes("DNSDemographic_DNSD_admin_member"))
        {
            setShowSettingsMenu(true);
        }
    }, [user, userGroups])

  return (
        <Drawer
            variant={props.variant}
            container={container}
            open={mobileMenuOpen}
            onClose={() => {toggleMobileOpen(false)}}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{background:"#cfdaf0"}}
            PaperProps={{sx: {background: "#cfdaf0", backgroundRepeat: "no-repeat", backgroundPosition: "bottom center", },}}
        >
                
            <Box
                sx={{ width: props.drawerWidth, display:`flex`, flexShrink: { sm: 0 }  }}
            >   
                
                <List disablePadding sx={{width:`100%`, background:"#e5eaf6", color:"#1a4063"}}>
                    <AppLogo />
                    <Divider />
                        <ListItem button onClick={() => navigateTo('/Overview')} selected={'Overview' === currentRoute}>
                            <ListItemIcon><InfoIcon /></ListItemIcon>
                            <ListItemText> Overview </ListItemText>
                        </ListItem>
                        {
                            dashboardSettings.map(item => {
                                return (
                                    <ListItem key={item.id} button onClick={() => navigateTo('/Dashboard/'+item.id, {name:item.name, description:item.description})} selected={'Dashboard' === currentRoute}>
                                        <ListItemIcon><DashboardIcon /></ListItemIcon>
                                        <ListItemText> {item.name} </ListItemText>
                                    </ListItem>
                                )
                            })
                        }
                     <Divider />
                     {
                         showSettingsMenu &&
                            <ListItem button onClick={() => navigateTo('/Settings/')} selected={'Settings' === currentRoute}>
                                <ListItemIcon><SettingsTwoToneIcon /></ListItemIcon>
                                <ListItemText> Settings </ListItemText>
                            </ListItem>
                     }
                     {
                         showSettingsMenu &&
                            <ListItem button onClick={() => navigateTo('/Feedback/')} selected={'Feedback' === currentRoute}>
                                <ListItemIcon><MessageIcon /></ListItemIcon>
                                <ListItemText> Feedback </ListItemText>
                            </ListItem>
                     }
                </List>
            </Box>
            <span
                style={{
                  marginTop: "auto",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                  gap: "15px",
                }}
              >
                <FeedbackFormDialog />
                <img src={BreakpointLogo} alt="Powered by Breakpoint" />
            </span>
        </Drawer>
  );
}

export {AppNavigation};