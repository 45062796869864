import React from 'react';
import OverviewHeaderBackground from '../Media/OverviewHeaderBackground.png';
import { Container, Box, Typography, Grid, Tooltip } from '@mui/material';

import {useDashboardSettings} from '../Context/Auth'

const Overview = () => {
    
    const {dashboardSettings} = useDashboardSettings();

    return(
        <Container>
            <Grid
                container
                direction="column"
                spacing={2}
                justifyContent="flex-end"
                marginTop={4}
                sx={{backgroundImage:`url(${OverviewHeaderBackground})` ,backgroundRepeat:"no-repeat",backgroundSize:"100% 100%", backgroundPosition:"bottom right"}}
            >
                <Grid item height="100px"></Grid>
                <Grid item width="65%" sx={{color:"#FFF"}}>
                    <Typography  variant="h5">
                        Welcome to ICANN DNS Demographics
                    </Typography>
                    <Typography  variant="h6">
                        Dashboards and visualizations that provide information about the DNS infrastructure landscape at the Top-Level Domain (TLD) level.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item flex={4} marginY={2}>
                    <Box sx={{color:"#1a4063"}}>
                        <Typography>
                            Each dashboard contains visualizations that can be filtered by jurisdiction or by region as needed. Navigate to the following dashboards using the menu on the left.
                        </Typography>
                    </Box>
                    {
                        dashboardSettings.map((menuItem, itemIndex) => {
                            return(
                                <Box key={`${menuItem.guid}_box`}>
                                    <br/>
                                    <Tooltip title={menuItem.description}>
                                        <Typography variant="h6" sx={{color:"#047bc1"}}>
                                            {menuItem.name}
                                        </Typography>
                                    </Tooltip>
                                        {menuItem.overview?.pretext}
                                        <ul>
                                            {
                                                menuItem.overview?.items.map((overviewItem, overviewItemIndex) => {
                                                    return(
                                                        <li key={`${menuItem.guid}_bullet_${overviewItemIndex}`}>
                                                            {overviewItem}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        {menuItem.overview?.posttext}
                                </Box>
                            )
                        })
                    }
                    <br/>
                </Grid>
                <Grid item flex={2}>
                    <Grid container direction="column">
                        <Grid sx={{background:"#a4bde3"}} margin={2} padding={1} borderRadius={1}>
                            <Box>
                            <Typography sx={{color:"#002b49", fontSize:"0.9rem", fontWeight:"bold"}}>
                                    PLEASE NOTE
                                </Typography>
                                <ul>
                                    <li><Typography sx={{fontSize:"0.8rem", margin:1}}>Most of the visualizations are interactive. Click on the visualization to filter a specific item.</Typography></li>
                                    <li><Typography sx={{fontSize:"0.8rem", margin:1}}>Dashboards can only display data from at least two days ago. If you receive a “no data” message, it is likely because the data is not yet available, or you are trying to find data from a Saturday or Sunday (only the TLD APEX History ‘TLD Apex Details’ dashboard can load data for Saturdays and Sundays.) Try searching for a different date.</Typography></li>
                                    <li><Typography sx={{fontSize:"0.8rem", margin:1}}>Some visualizations may take a bit of time to load because of the hardware resource consumption and the number of visualizations on the dashboard.</Typography></li>
                                </ul>
                            </Box>
                        </Grid>
                        <Grid sx={{background:"#a4bde3"}} margin={2} padding={1} borderRadius={1}>
                            <Box>
                                <Typography sx={{color:"#002b49", fontSize:"0.9rem", fontWeight:"bold"}}>
                                    The dashboards and visualizations aim to provide users with:
                                </Typography>
                                <ul>
                                    <li><Typography sx={{fontSize:"0.8rem", margin:1}}>Easily digestible information about the global and country code TLDs (ccTLDs) of the DNS landscape.</Typography></li>
                                    <li><Typography sx={{fontSize:"0.8rem", margin:1}}>A means to quickly identify various DNS standards and best practices, such as implementation levels and gaps in TLD DNS infrastructures.</Typography></li>
                                    <li><Typography sx={{fontSize:"0.8rem", margin:1}}>Information to enable appropriate tailoring of messaging and capacity development strategies toward TLD actors (registries, registrars, administrative and technical contacts, etc.)</Typography></li>
                                </ul>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Overview;