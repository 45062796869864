import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { AuthProvider } from './Context/Auth';
import Amplify, { Auth } from 'aws-amplify';

import {AppSettingsProvider} from './Context/AppSettings';

import awsExports from './aws-exports';
import awsAuth from './awsauth';
Amplify.configure(awsExports);

Auth.configure({ oauth: awsAuth })

ReactDOM.render(
  <React.StrictMode>
        <AppSettingsProvider>
          <AuthProvider>
              <App />
          </AuthProvider>
        </AppSettingsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
